h1 {
  font-size: 42px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 16px;
}

p, label {
  font-size: 18px;
}

h5 {
  font-size: 12px;
}

a {
  font-size: 18px;
}

button {
  font-size: 16px;
  font-weight: 700;
  padding: 12px 24px;
}

input, textarea {
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  background-color: transparent;
}

.btn1 {
  background-color: white;
  color: black;
  border-radius: 50px;
}

.btn2 {
  background-color: black;
  color: white;
  border-radius: 5px;
}

.btn3 {
  background-color: transparent;
  border: 2px solid black;
  color: black;
  border-radius: 5px;
}

.btn4 {
  background-color: white;
  color: black;
  border-radius: 5px;
}

.btn5 {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  border-radius: 5px;
}

.unselected {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid black;
}

.selected {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: black;
}